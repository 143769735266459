import { Dialog } from "@headlessui/react";
import AddressForm from "../shared/AddAddressForm";

export default function AddAddressDialog({
  isOpen,
  setIsOpen,
  isEdit,
  addressToBeEdited,
  setAddressToBeEdited,
  googleMapsAPIKEY,
  actionData,
  addresses,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed inset-0 z-10 flex items-start justify-center overflow-auto pt-[72px] lg:p-4"
      aria-labelledby="AddAddressDialog-dialog-title"
      aria-describedby="AddAddressDialog-dialog-description"
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => setIsOpen(false)}
      />
      <div className="relative bg-white text-black rounded-tl-lg rounded-tr-lg lg:rounded-bl-lg lg:rounded-br-lg shadow-lg max-w-[1024px] lg:max-w-[500px] w-full h-full overflow-scroll lg:h-auto z-20">
        <div
          id="AddAddressDialog-dialog-title"
          className="fixed z-[2] lg:relative top-[72px] shadow shadow-black/10 lg:shadow-none lg:top-0 rounded-tl-lg rounded-tr-lg left-0 right-0 px-4 h-[60px] bg-white border-b border-b-[#f0f0f0] text-lg font-bold flex items-center justify-between"
        >
          <div>Add Address</div>
          <button onClick={() => setIsOpen(false)}>
            <svg width="28" height="28" viewBox="0 0 20 20">
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,16 L4,4"
              ></path>
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,4 L4,16"
              ></path>
            </svg>
          </button>
        </div>
        <div
          id="AddAddressDialog-dialog-description"
          className="z-[1] mt-[60px] lg:mt-0 py-4 px-4 text-sm text-gray-600"
        >
          <AddressForm
            onCancel={() => {
              setIsOpen(false);
            }}
            googleMapsAPIKEY={googleMapsAPIKEY}
            actionData={actionData}
            addresses={addresses}
            isEdit={isEdit}
            initialValues={addressToBeEdited}
          />
        </div>
      </div>
    </Dialog>
  );
}
