import { Dialog } from "@headlessui/react";
import AddressItem from "./AddressItem";
import { useRevalidator } from "@remix-run/react";
import { LoadingIndicator } from "./LoadingIndicator";
import { useState } from "react";

export default function AddressesDialog({
  addresses,
  selectedAddress,
  isOpen,
  setIsOpen,
  setIsAddAddressDialogOpen,
}) {
  const revalidator = useRevalidator();
  const [tempSelectedAddress, setTempSelectedAddress] =
    useState(selectedAddress);
  const handleSelectedAddress = async (address) => {
    if (!address?.id) {
      return;
    }
    setTempSelectedAddress(address);
    try {
      const response = await fetch("/api/select-address", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          addressId: address.id, // Replace with your dynamic address ID
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || "Something went wrong");
      }

      revalidator.revalidate();
    } catch (error) {
      console.error("Error selecting address:", error);
      console.log(error.message || "An error occurred");
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed inset-0 z-10 flex items-start justify-center overflow-auto pt-[72px] lg:m-0 lg:p-4"
      aria-labelledby="AddressesDialog-dialog-title"
      aria-describedby="AddressesDialog-dialog-description"
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => setIsOpen(false)}
      />

      <div className="relative bg-white rounded-tl-lg rounded-tr-lg lg:rounded-bl-lg lg:rounded-br-lg shadow-lg max-w-[1024px] lg:max-w-[500px] w-full h-full overflow-scroll lg:h-auto z-20">
        <div
          id="AddressesDialog-dialog-title"
          className="fixed lg:relative top-[72px] z-10 shadow shadow-black/10 lg:shadow-none lg:top-0 rounded-tl-lg rounded-tr-lg left-0 right-0 px-4 h-[60px] bg-white border-b border-b-[#f0f0f0] text-lg font-bold flex items-center justify-between"
        >
          <div className="flex gap-2 items-center">
            <div>Select your address </div>
            {revalidator.state === "loading" ? <LoadingIndicator /> : null}
          </div>

          <button onClick={() => setIsOpen(false)}>
            <svg width="28" height="28" viewBox="0 0 20 20">
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,16 L4,4"
              ></path>
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,4 L4,16"
              ></path>
            </svg>
          </button>
        </div>

        <div
          id="AddressesDialog-dialog-description"
          className="my-[60px] lg:my-0 py-4 px-4 text-sm text-gray-600"
        >
          <div className="text-base text-center max-w-[300px] mx-auto mb-[20px]">
            Select your address to view product availability and delivery
            options.
          </div>
          {addresses.map((address, index) => {
            return (
              <AddressItem
                key={address.id}
                address={address}
                selectedAddress={tempSelectedAddress}
                handleSelectedAddress={
                  revalidator.state === "loading"
                    ? () => {}
                    : handleSelectedAddress
                }
                isFirst={index === 0}
                isLast={index + 1 === addresses.length}
              />
            );
          })}
        </div>
        <div
          id="AddressesDialog-dialog-footer"
          className="fixed lg:relative z-10 bottom-[0px] lg:top-0 left-0 right-0 px-4 h-[60px] bg-white border-t border-t-[#f0f0f0] text-base flex items-center justify-between"
        >
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              setIsAddAddressDialogOpen(true);
            }}
            className="underline hover:no-underline"
          >
            +Add address
          </button>
          <button
            type="button"
            disabled={revalidator.state === "loading"}
            onClick={() => setIsOpen(false)}
            className="bg-black text-primary px-4 h-[40px] rounded-lg hover:bg-black/90 disabled:bg-[#dddddd] disabled:text-black transition-all duration-300"
          >
            Continue
          </button>
        </div>
      </div>
    </Dialog>
  );
}
