import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { v4 as uuidv4 } from "uuid";

// Load plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const pluralize = (count, singular, plural = null) => {
  const pluralForm = plural || `${singular}s`;

  return count === 1 ? singular : pluralForm;
};

export const formatAddress = (addressComponents) => {
  let streetNumber = "";
  let route = "";
  let city = "";
  let state = "";
  let postalCode = "";
  let country = "";

  addressComponents.forEach((component) => {
    const types = component.types;

    if (types.includes("street_number")) {
      streetNumber = component.long_name;
    }
    if (types.includes("route")) {
      route = component.long_name;
    }
    if (types.includes("locality")) {
      city = component.long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      state = component.short_name;
    }
    if (types.includes("postal_code")) {
      postalCode = component.long_name;
    }
    if (types.includes("country")) {
      country = component.long_name;
    }
  });

  // Build the formatted address string
  const formattedAddress = `${streetNumber} ${route}, ${city}, ${state} ${postalCode}, ${country}`;

  return formattedAddress;
};

export const getShipDate = () => {
  // Get current date in New York timezone
  const compareDate = dayjs().tz("America/New_York");

  // Get the day of the week (Monday is 1, Sunday is 0)
  const dayOfWeek = compareDate.day();

  let shipDate;

  if (dayOfWeek === 1) {
    // Monday
    shipDate = compareDate.add(1, "day"); // Ships on Tuesday
  } else if (dayOfWeek === 2) {
    // Tuesday
    shipDate = compareDate.add(1, "day"); // Ships on Wednesday
  } else if (dayOfWeek === 3) {
    // Wednesday
    shipDate = compareDate.add(5, "day"); // Ships on Monday
  } else if (dayOfWeek >= 4 || dayOfWeek === 0) {
    // Thursday to Sunday
    shipDate = compareDate.add(8 - dayOfWeek, "day"); // Ships on Monday
  }

  // Return the formatted ship date as YYYY-MM-DD
  return shipDate.format("YYYY-MM-DD");
};

export const getInitials = (firstName, lastName) => {
  if (firstName || lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return "Account";
};

export const validateZipCodeWithGoogleAPI = async (
  zipCode,
  googleAPIKey,
  expectedCity
) => {
  // Validate input for empty zip code
  if (!zipCode || !googleAPIKey) {
    return {
      isValid: false,
      message: "Invalid request. ZIP Code or API key is missing.",
    };
  }

  if (!expectedCity) {
    return {
      isValid: false,
      message:
        "Please enter the city that corresponds to the provided ZIP code.",
    };
  }

  // Fetch geocode data from Google API
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&components=country:US&key=${googleAPIKey}`
    );

    const data = await response.json();

    // Check if the response is not OK or if there are no results
    if (data.status !== "OK" || !data.results.length) {
      return {
        isValid: false,
        message: "The ZIP Code you entered is not valid.",
      };
    }

    let isValidZip = false;
    let foundCity = null;
    let suggestedCities = [];

    // Iterate over address components to find postal code and city (locality)
    data.results.forEach((result, index) => {
      result.address_components.forEach((component) => {
        // Validate postal code
        if (component.types.includes("postal_code")) {
          isValidZip = true;
        } else {
          if (!suggestedCities.includes(component.long_name)) {
            suggestedCities.push(component.long_name);
          }
        }

        if (
          component.long_name == expectedCity ||
          component.short_name == expectedCity
        ) {
          foundCity = component.long_name;
        }
      });
    });

    // If ZIP code is not valid, return error
    if (!isValidZip) {
      return {
        isValid: false,
        message: "The ZIP Code you entered is not valid.",
      };
    }
    // Compare normalized cities with flexibility
    if (!foundCity && !suggestedCities.includes(expectedCity)) {
      return {
        isValid: true,
        isSameCity: false,
        message: `The ZIP Code is valid but does not match the expected city "${expectedCity}". Provided ZIP code is found in "${suggestedCities.toString()}".`,
      };
    }

    // If all checks pass
    return {
      isValid: true,
      isSameCity: true,
      message: "The ZIP Code is valid and matches the expected city.",
    };
  } catch (error) {
    // Handle any errors from the fetch call
    console.error("Error fetching ZIP code data:", error);
    return {
      isValid: true,
      isSameCity: true,
      message:
        "The validation request failed, validate the zipcode as a fallback.",
    };
  }
};
export const transformTimeslots = (selectedAddress) => {
  const { service_area } = selectedAddress;
  if (!service_area || !service_area.time_slots) return [];

  const timeSlots = service_area.time_slots;
  const blackoutDays = new Set(service_area.blackout_days || []);
  const daysAvailable = parseInt(service_area.days_available_future, 10) || 7;

  const now = dayjs().tz("America/New_York"); // Current time in New York timezone
  const today = now.startOf("day");
  const result = [];
  let collectedDays = 0; // Track the number of valid days collected

  // Helper to format time
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const formattedTime = dayjs()
      .tz("America/New_York")
      .hour(hours)
      .minute(minutes)
      .format("h:mm A");
    return formattedTime;
  };

  let i = 0; // Index for iterating through future days
  while (collectedDays < daysAvailable) {
    const currentDate = today.add(i, "day");
    const dayName = currentDate.format("ddd");
    const fullDate = currentDate.format("YYYY-MM-DD");
    const date = currentDate.format("MMM D");

    // Skip blackout days
    if (blackoutDays.has(fullDate)) {
      i++;
      continue;
    }

    // Get time slots for the day
    const dayOfWeek = currentDate.format("dddd");
    const slots = timeSlots[dayOfWeek];

    if (slots) {
      const times = slots.map((slot) => {
        const cutoffTime = dayjs.tz(
          `${fullDate}T${slot.cutoff}`,
          "America/New_York"
        );
        const isPastCutoff =
          currentDate.isSame(today) && now.isAfter(cutoffTime);

        return {
          id: uuidv4(),
          label: `${formatTime(slot.start)} - ${formatTime(slot.end)}`,
          value: `${formatTime(slot.start)} - ${formatTime(slot.end)}`,
          is_enable: !isPastCutoff,
          inner_text: "",
          fullDate,
        };
      });

      if (times.length > 0) {
        result.push({
          id: uuidv4(),
          dayName,
          date,
          fullDate,
          times,
        });
        collectedDays++; // Increment the count of valid days collected
      }
    }

    i++; // Move to the next day
  }

  return result;
};

export const formatProductVariants = (products) => {
  if (!products) {
    return [];
  }
  const processedProducts = products.map((product) => {
    let variants = [];

    // Check if the product requires weight-related calculations
    if (product.is_weight_required) {
      variants = product.variants.map((variant) => {
        const price = parseFloat(variant.price);
        const weight = parseFloat(
          variant.weight.replace(` ${variant.unit_type}`, "")
        );
        const price_per_unit = (price / weight).toFixed(2);
        return {
          ...variant,
          price: price.toFixed(2),
          price_per_unit,
          weight_without_unit: weight.toFixed(2),
        };
      });
    } else {
      // Simple price formatting for products without weight requirements
      variants = product.variants.map((variant) => {
        const price = parseFloat(variant.price);
        return {
          ...variant,
          price: price.toFixed(2),
        };
      });
    }

    // Reverse the variants
    variants = variants.reverse();

    // Return the processed product with updated variants
    return {
      ...product,
      variants,
    };
  });

  return processedProducts;
};

// function gtag_report_conversion(url) {
//   var callback = function () {
//     if (typeof(url) != 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', {
//       'send_to': 'AW-16794482076/t2mWCLKv4PEZEJzrncg-',
//       'value': 1.0,
//       'currency': 'USD',
//       'event_callback': callback
//   });
//   return false;
// }

export const trackAddToCart = (item) => {
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: "USD",
      value: item.price,
      items: [
        {
          item_name: item.title,
          item_id: item.id,
          price: item.price,
          quantity: 1,
        },
      ],
    },
  });
  if (typeof gtag === "function") {
    gtag("event", "conversion", {
      send_to: "AW-16794482076/t2mWCLKv4PEZEJzrncg-",
      value: item.price,
      currency: "USD",
    });
  } else {
    console.warn("Gtag is not initialized.");
  }
};

export const trackBeginCheckout = (cart, checkout_id) => {
  const items = cart.map((item) => ({
    item_name: item.product.title,
    item_id: item.product.id,
    price: item.product.price,
    quantity: item.quantity,
  }));

  const value = cart.reduce(
    (total, item) => total + parseFloat(item.product.price) * item.quantity,
    0
  );

  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      transaction_id: checkout_id,
      currency: "USD",
      value,
      items,
    },
  });

  if (typeof gtag === "function") {
    gtag("event", "conversion", {
      send_to: "AW-16794482076/PX4kCLWv4PEZEJzrncg-",
      value: value,
      currency: "USD",
    });
  } else {
    console.warn("Gtag is not initialized.");
  }
};

export const trackPurchase = (order) => {
  if (!order || !order.order_items) {
    return;
  }
  const items = order.order_items.map((item) => ({
    item_name: item.product_name,
    item_id: item.product_id,
    price: item.price,
    quantity: item.quantity,
  }));

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: order.order_id,
        currency: "USD",
        value: order.total,
        items,
      },
    });
  }

  if (typeof gtag === "function") {
    gtag("event", "conversion", {
      send_to: "AW-16794482076/8KA6CLiv4PEZEJzrncg-",
      value: order.total,
      currency: "USD",
      transaction_id: order.order_id,
    });
  } else {
    console.warn("Gtag is not initialized.");
  }
};
