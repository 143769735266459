import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useActionData,
  useLocation,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import { useLoaderData } from "@remix-run/react";
import {
  LANGUAGE,
  BRAND_ID,
  STORE_ID,
  API_VERSION,
  BASE_URL,
  FETCH_DATA,
  VALIDATE_ZIPCODE,
  DEVICE_DATA,
} from "./config/constants";
import Maintenance from "./components/Maintenance";
import { CartProvider, useCart } from "./context/CartContext";
import { getUserFromSession } from "./utils/session.server";

import "./tailwind.css";
import MobileNavigation from "./components/shared/MobileNavigation";
import React, { useEffect } from "react";
import Error from "./components/shared/Error";
import ErrorCatchAll from "./components/shared/ErrorCatchAll";
import AddressesDialog from "./components/shared/AddressesDialog";
import AddAddressDialog from "./components/checkout/AddAddressDialog";
import { validateZipCodeWithGoogleAPI } from "./utils/helper";

export const loader = async ({ request }) => {
  const url = new URL(request.url);
  const user = await getUserFromSession(request);
  const pathname = url.pathname;

  if (
    user &&
    user.is_logged_in &&
    (!user.first_name || !user.email) &&
    !pathname.includes("account-update")
  ) {
    return redirect("/account-update");
  }

  let addresses = [];
  let selectedAddress = null;
  if (user && user.is_logged_in) {
    const selectedAddressesUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/user_selected_addresses/getUserSelectedAddress/${user.uid}`;
    const addressesUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/service_area_zip_codes/getUserAddresses/${user.uid}`;
    const [addressesResponse, selectedAddressResponse] = await Promise.all([
      FETCH_DATA(addressesUrl, "data"),
      FETCH_DATA(selectedAddressesUrl, "data"),
    ]);
    addresses =
      addressesResponse.success && addressesResponse.data?.length > 0
        ? addressesResponse.data
        : [];
    const selectedAddressId = selectedAddressResponse?.data?.UserAddress.id;
    if (selectedAddressId && addresses?.length > 0) {
      selectedAddress = addresses.find((address) => {
        return address.id === selectedAddressId;
      });
    }
  }

  const googleMapsAPIKEY = process.env.GOOGLE_MAPS_API_KEY;

  let query = url.searchParams.get("query");
  let pageNo = url.searchParams.get("pageNo") || 1;
  const limit = url.searchParams.get("limit") || 60;

  const categoryUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/inventory/getCategories?lang=${LANGUAGE}`;
  const brandUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/marketplace/homescreen/version?${DEVICE_DATA}&lang=${LANGUAGE}`;
  const brandClosedUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/marketplace/homescreen/brand_status`;

  const [collectionsResponse, brand, brandClosed] = await Promise.all([
    FETCH_DATA(categoryUrl, "data"),
    FETCH_DATA(brandUrl, "brand"),
    FETCH_DATA(brandClosedUrl, "data"),
  ]);
  if (brandClosed.success && brandClosed.data) {
    brand.status = brandClosed.data.status;
  }

  let collections =
    collectionsResponse.success &&
    collectionsResponse.data &&
    collectionsResponse.data.length > 0
      ? [...collectionsResponse.data]
      : [];

  if (
    selectedAddress?.service_area?.categories &&
    selectedAddress?.service_area?.categories.length > 0 &&
    collections.length > 0
  ) {
    const categoryIds = selectedAddress.service_area.categories;

    // Filter collections to include only those with an ID in categoryIds
    collections = collections.filter((collection) =>
      categoryIds.includes(collection.id)
    );
  }

  return json({
    collections,
    brand,
    user: { ...user, addresses, selectedAddress },
    search: {
      query,
      pageNo,
      limit,
    },
    googleMapsAPIKEY,
  });
};

export const action = async ({ request }) => {
  const user = await getUserFromSession(request);
  if (!user?.is_logged_in) return redirect("/login");

  const url = new URL(request.url);
  const formData = await request.formData();
  const zipcode = formData.get("zipcode");
  const formId = formData.get("formId");
  const action = formData.get("action");
  const googleMapsAPIKEY = process.env.GOOGLE_MAPS_API_KEY;

  if (action === "addEditAddress") {
    try {
      const addressId = formData.get("addressId");
      const firstName = formData.get("firstName");
      const lastName = formData.get("lastName");
      const phone = formData.get("phone");
      const address = formData.get("address");
      const city = formData.get("city");
      const zipCode = formData.get("zipCode");
      const apartment = formData.get("apartment");
      const defaultAddress = formData.get("defaultAddress");
      const email = user.email; // Hidden field
      const lat = formData.get("lat"); // Hidden field
      const lng = formData.get("lng"); // Hidden field

      const validationErrors = [];
      if (!firstName) {
        validationErrors.push({
          field: "firstName",
          message: "Please enter your first name",
          type: "required",
          hasError: true,
        });
      }

      if (!lastName) {
        validationErrors.push({
          field: "lastName",
          message: "Please enter your last name",
          type: "required",
          hasError: true,
        });
      }
      if (!phone) {
        validationErrors.push({
          field: "phone",
          message: "Please enter a valid phone number",
          type: "required",
          hasError: true,
        });
      }
      if (!address) {
        validationErrors.push({
          field: "address",
          message: "Please enter a valid address",
          type: "required",
          hasError: true,
        });
      }
      if (!lat || !lng) {
        validationErrors.push({
          field: "address",
          message: "Please use the autocomplete to enter a valid address",
          type: "required",
          hasError: true,
        });
      }
      if (!city) {
        validationErrors.push({
          field: "city",
          message: "Please enter a valid address",
          type: "required",
          hasError: true,
        });
      }
      if (!zipCode) {
        validationErrors.push({
          field: "zipCode",
          message: "Please enter a valid ZIP Code",
          type: "required",
          hasError: true,
        });
      } else {
        let zipcodeValidation = await validateZipCodeWithGoogleAPI(
          zipCode,
          googleMapsAPIKEY,
          city
        );

        if (!zipcodeValidation.isValid || !zipcodeValidation.isSameCity) {
          validationErrors.push({
            field: "zipCode",
            message: zipcodeValidation.message,
            type: "required",
            hasError: true,
          });
        }
      }

      if (!apartment) {
        validationErrors.push({
          field: "apartment",
          message: "Please enter your apartment, suite, house, office",
          type: "required",
          hasError: true,
        });
      }

      if (validationErrors.length > 0) {
        return {
          success: false,
          validationErrors,
        };
      }

      const addressesUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/delivery_zones/deliveryAddress`;

      const addressMeta = {
        user_id: user.uid,
        method: "ADD",
        first_name: firstName,
        last_name: lastName,
        mobile: phone,
        address2: apartment,
        address,
        email,
        city,
        zipcode: zipCode,
        lat,
        lng,
        address_type: "home",
        set_default_address: defaultAddress == "on" ? "1" : "0",
      };

      if (addressId) {
        //update address
        addressMeta.method = "EDIT";
        addressMeta.address_id = addressId;
      }

      await FETCH_DATA(
        addressesUrl,
        "data",
        "POST",
        addressMeta,
        "application/x-www-form-urlencoded"
      );

      // 2. Fetch user addresses
      const getAddressesUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/service_area_zip_codes/getUserAddresses/${user.uid}`;

      let addresses = await FETCH_DATA(getAddressesUrl, "data");

      return {
        success: validationErrors.length === 0,
        action: "addEditAddress",
        validationErrors,
        addresses:
          addresses.success && addresses.data?.length > 0 ? addresses.data : [],
      };
    } catch (error) {
      console.error("Error fetching checkout data:", error);
      return {
        success: false,
        action: "addEditAddress",
        message: "An unexpected error occurred.",
      };
    }
  }

  if (action === "deleteAddress") {
    const addressId = formData.get("address_id");
    const addressesUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/delivery_zones/deliveryAddress`;

    const addressMeta = {
      user_id: user.uid,
      method: "DELETE",
      address_id: addressId,
    };

    const deleteAddress = await FETCH_DATA(
      addressesUrl,
      "message",
      "POST",
      addressMeta,
      "application/x-www-form-urlencoded"
    );

    if (deleteAddress.success) {
      return json({
        success: true,
        action: "deleteAddress",
        message: deleteAddress.data,
      });
    }

    return json({
      success: false,
      action: "deleteAddress",
      message: "An unexpected error occurred.",
    });
  }

  if (formId === "zipcodeForm") {
    if (!zipcode) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "There was an error, please choose another option.",
        },
      });
    }

    const isZipcodeValid = VALIDATE_ZIPCODE(zipcode);
    if (!isZipcodeValid) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "Please enter a valid zipcode.",
        },
      });
    }

    const numberOfPackages = 1;
    const transitData = { zipcode, numberOfPackages };

    // Fetch transit data
    const transitResponse = await fetch(`${url.origin}/api/checkout/transit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transitData),
    });

    const transitResponseData = await transitResponse.json();

    if (transitResponseData.error) {
      return json({
        zipcodeResponse: {
          error: true,
          message: "There was an error, please choose another option.",
        },
      });
    }

    const { businessTransitDays, deliveryDate } = transitResponseData;

    const zipcodeUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/service_area_zip_codes/validateZipcode/${zipcode}`;
    const zipcodeValidation = await FETCH_DATA(zipcodeUrl, "data");

    return json({
      zipcodeResponse: {
        success: true,
        data: {
          business_transit_days: businessTransitDays,
          delivery_date: deliveryDate,
          business: zipcodeValidation.success ? "local" : "nationwide",
        },
      },
    });
  }

  return json({ error: "Invalid form submission" }, { status: 400 });
};

export const links = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }) {
  const location = useLocation();

  if (location.pathname === "/sitemap.xml") {
    return null; // Prevent layout wrapping
  }
  if (location.pathname === "/robots.txt") {
    return null; // Prevent layout wrapping
  }

  useEffect(() => {
    addGtmScripts();
  }, []);

  const grayBackgroundRoutes = [
    "/account",
    "/account/",
    "/orders",
    "/orders/",
    /^\/thank-you\/\d+$/,
    /^\/order\/\d+$/,
  ];
  const isGrayBackground = grayBackgroundRoutes.some((route) =>
    typeof route === "string"
      ? route === location.pathname
      : route.test(location.pathname)
  );
  return (
    <CartProvider>
      <html lang="en" className="bg-white">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script src="https://tokenization.banquestgateway.com/tokenization/v0.2"></script>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB1meGExCFakYM_b63qRMiMmEe_WuN_Hak&libraries=places`}
            async
          ></script>

          <Meta />
          <Links />
        </head>
        <body
          className={
            isGrayBackground ? "bg-gray-100 text-black" : "bg-white text-black"
          }
        >
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-WN56295X"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>

          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    </CartProvider>
  );
}

export function App() {
  const { brand, user, collections, search, googleMapsAPIKEY } =
    useLoaderData();
  const {
    isAddressesDialogOpen,
    setIsAddressesDialogOpen,
    isAddAddressDialogOpen,
    setIsAddAddressDialogOpen,
    addressToBeEdited,
    setAddressToBeEdited,
  } = useCart();

  const location = useLocation();
  const navigate = useNavigate();
  const actionData = useActionData();

  useEffect(() => {
    if (
      actionData &&
      actionData.success &&
      actionData.action === "addEditAddress" &&
      actionData.addresses
    ) {
      // Merge addresses only if there are new ones from actionData
      let actionDataAddresses = actionData.addresses;

      // Filter out duplicates (if any)
      let newAddress = actionDataAddresses.find(
        (obj2) => !user.addresses.some((obj1) => obj1.id === obj2.id)
      );

      if (newAddress) {
        // Place the new address at the top of the array
        let sortedAddresses = [
          newAddress,
          ...actionDataAddresses.filter((addr) => addr.id !== newAddress.id),
        ];

        setIsAddAddressDialogOpen(false);
        setIsAddressesDialogOpen(true);
      }
    }

    if (
      actionData &&
      actionData.success &&
      actionData.action === "deleteAddress"
    ) {
      setIsAddAddressDialogOpen(false);
    }
  }, [actionData]);

  useEffect(() => {
    const checkUser = async () => {
      if (
        user &&
        user.is_logged_in &&
        (!user.first_name || !user.email) &&
        !location.pathname.includes("account-update")
      ) {
        navigate("/account-update");
      }
    };

    checkUser();
  }, [location.pathname, navigate]);

  // Handle error state
  if (brand.error) {
    return <p>Error loading brand: {brand.message}</p>;
  }

  // Handle empty data state
  if (brand.loaded && brand.success && !brand.data) {
    return <p>No brand settings available.</p>;
  }

  if (brand?.status != "1") {
    return <Maintenance />;
  }

  return (
    <div className="pb-[102px] lg:pb-0">
      <Outlet context={{ brand, user, collections, search }} />

      <AddressesDialog
        addresses={user.addresses}
        selectedAddress={user.selectedAddress}
        isOpen={isAddressesDialogOpen}
        setIsOpen={setIsAddressesDialogOpen}
        setIsAddAddressDialogOpen={setIsAddAddressDialogOpen}
      />
      <AddAddressDialog
        isOpen={isAddAddressDialogOpen}
        setIsOpen={setIsAddAddressDialogOpen}
        googleMapsAPIKEY={googleMapsAPIKEY}
        actionData={actionData}
        addresses={user.addresses}
        addressToBeEdited={addressToBeEdited}
        setAddressToBeEdited={setAddressToBeEdited}
        isEdit={addressToBeEdited !== null}
      />
      <MobileNavigation />
    </div>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // Check if the error status is 404
  if (error.status === 404) {
    return <Error />;
  }

  // Fallback for other errors
  return <ErrorCatchAll error={error} />;
}

export default function Root() {
  return <App />;
}

let gtmScriptAdded = false;

function addGtmScripts() {
  if (gtmScriptAdded) return; // Prevent duplicate execution

  const GTM_ID = "GTM-WN56295X";
  const GA_ID = "AW-16794482076";
  const GTM_URL = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
  const GA_URL = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;

  // Initialize dataLayer globally
  window.dataLayer = window.dataLayer || [];

  // Add GTM script dynamically
  addScript(GTM_URL);

  // Add Google Ads (gtag.js) script dynamically
  addScript(GA_URL, () => {
    // Define gtag function globally
    window.gtag = function () {
      window.dataLayer.push(arguments); // Push arguments as an array to dataLayer
    };
    // Initialize gtag.js with configuration
    gtag("js", new Date());
    gtag("config", GA_ID);
  });

  gtmScriptAdded = true;
}

function addScript(src, onLoadCallback) {
  if (document.querySelector(`script[src="${src}"]`)) {
    console.warn(`Script already loaded: ${src}`);
    if (onLoadCallback) onLoadCallback(); // Invoke callback if script is already loaded
    return;
  }

  const script = document.createElement("script");
  script.async = true;
  script.src = src;
  script.onload = onLoadCallback;
  document.head.appendChild(script);
}
