import { Link, useOutletContext } from "@remix-run/react";
import { useCart } from "../../context/CartContext";

export default function Location() {
  const { user } = useOutletContext();
  const { selectedAddress } = user;
  const { setIsAddressesDialogOpen, setIsAddAddressDialogOpen } = useCart();

  if (!user?.is_logged_in) {
    return (
      <Link
        to="/login"
        className="text-white h-[50px] flex justify-center items-center px-3"
        reloadDocument
      >
        + Add Address
      </Link>
    );
  }

  if (selectedAddress) {
    return (
      <button
        type="button"
        className="text-white h-[50px] lg:w-[270px] text-[16px] flex justify-start items-center text-left gap-1 px-3"
        onClick={() => {
          setIsAddressesDialogOpen(true);
        }}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          style={{ flex: "0 0 18px" }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C8.57128 16 9.11528 15.7557 9.49482 15.3287L9.49749 15.3257L9.50163 15.321L9.51421 15.3068L9.55592 15.2589C9.59084 15.2187 9.63972 15.1618 9.70056 15.0897C9.82213 14.9455 9.9921 14.7399 10.1944 14.4838C10.5973 13.9738 11.1368 13.2533 11.6798 12.4129C12.6943 10.8429 14 8.44131 14 6.11932C14 2.79553 11.3687 0 8 0C4.63128 0 2 2.79553 2 6.11932C2 8.44131 3.30574 10.8429 4.32018 12.4129C4.86322 13.2533 5.40275 13.9738 5.80562 14.4838C6.0079 14.7399 6.17787 14.9455 6.29944 15.0897C6.36028 15.1618 6.40916 15.2187 6.44408 15.2589L6.48579 15.3068L6.49837 15.321L6.50251 15.3257L6.50518 15.3287C6.88472 15.7557 7.42872 16 8 16ZM8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
            fill="currentColor"
          ></path>
        </svg>
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          {selectedAddress.address}
        </span>
      </button>
    );
  }

  if (user && user.addresses && user.addresses.length > 0) {
    return (
      <button
        type="button"
        className="text-white h-[50px] flex justify-center items-center px-3"
        onClick={() => {
          setIsAddressesDialogOpen(true);
        }}
      >
        Select Address
      </button>
    );
  }

  return (
    <button
      type="button"
      className="text-white h-[50px] flex justify-center items-center px-3"
      onClick={() => {
        setIsAddAddressDialogOpen(true);
      }}
    >
      + Add Address
    </button>
  );
}
