import { Link, useOutletContext } from "@remix-run/react";
import { useCart } from "../../context/CartContext";
import SearchForm from "./SearchForm";
import React from "react";
import Location from "./Location";

export default function Header() {
  const { productCount, cartLoaded, cartIsEmpty, setIsAddressesDialogOpen } =
    useCart();
  const { collections, brand, user } = useOutletContext();

  console.log("User", user);

  const linklist = [...collections];

  return (
    <>
      <div className="lg:hidden text-[14px] leading-[16px] flex gap-1 justify-center items-center text-left bg-primary text-black py-3 px-4">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <rect
              x="1"
              y="4"
              width="30"
              height="24"
              rx="4"
              ry="4"
              fill="#fff"
            ></rect>
            <path
              d="M1.638,5.846H30.362c-.711-1.108-1.947-1.846-3.362-1.846H5c-1.414,0-2.65,.738-3.362,1.846Z"
              fill="#a62842"
            ></path>
            <path
              d="M2.03,7.692c-.008,.103-.03,.202-.03,.308v1.539H31v-1.539c0-.105-.022-.204-.03-.308H2.03Z"
              fill="#a62842"
            ></path>
            <path fill="#a62842" d="M2 11.385H31V13.231H2z"></path>
            <path fill="#a62842" d="M2 15.077H31V16.923000000000002H2z"></path>
            <path fill="#a62842" d="M1 18.769H31V20.615H1z"></path>
            <path
              d="M1,24c0,.105,.023,.204,.031,.308H30.969c.008-.103,.031-.202,.031-.308v-1.539H1v1.539Z"
              fill="#a62842"
            ></path>
            <path
              d="M30.362,26.154H1.638c.711,1.108,1.947,1.846,3.362,1.846H27c1.414,0,2.65-.738,3.362-1.846Z"
              fill="#a62842"
            ></path>
            <path
              d="M5,4h11v12.923H1V8c0-2.208,1.792-4,4-4Z"
              fill="#102d5e"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
            <path
              fill="#fff"
              d="M4.601 7.463L5.193 7.033 4.462 7.033 4.236 6.338 4.01 7.033 3.279 7.033 3.87 7.463 3.644 8.158 4.236 7.729 4.827 8.158 4.601 7.463z"
            ></path>
            <path
              fill="#fff"
              d="M7.58 7.463L8.172 7.033 7.441 7.033 7.215 6.338 6.989 7.033 6.258 7.033 6.849 7.463 6.623 8.158 7.215 7.729 7.806 8.158 7.58 7.463z"
            ></path>
            <path
              fill="#fff"
              d="M10.56 7.463L11.151 7.033 10.42 7.033 10.194 6.338 9.968 7.033 9.237 7.033 9.828 7.463 9.603 8.158 10.194 7.729 10.785 8.158 10.56 7.463z"
            ></path>
            <path
              fill="#fff"
              d="M6.066 9.283L6.658 8.854 5.927 8.854 5.701 8.158 5.475 8.854 4.744 8.854 5.335 9.283 5.109 9.979 5.701 9.549 6.292 9.979 6.066 9.283z"
            ></path>
            <path
              fill="#fff"
              d="M9.046 9.283L9.637 8.854 8.906 8.854 8.68 8.158 8.454 8.854 7.723 8.854 8.314 9.283 8.089 9.979 8.68 9.549 9.271 9.979 9.046 9.283z"
            ></path>
            <path
              fill="#fff"
              d="M12.025 9.283L12.616 8.854 11.885 8.854 11.659 8.158 11.433 8.854 10.702 8.854 11.294 9.283 11.068 9.979 11.659 9.549 12.251 9.979 12.025 9.283z"
            ></path>
            <path
              fill="#fff"
              d="M6.066 12.924L6.658 12.494 5.927 12.494 5.701 11.799 5.475 12.494 4.744 12.494 5.335 12.924 5.109 13.619 5.701 13.19 6.292 13.619 6.066 12.924z"
            ></path>
            <path
              fill="#fff"
              d="M9.046 12.924L9.637 12.494 8.906 12.494 8.68 11.799 8.454 12.494 7.723 12.494 8.314 12.924 8.089 13.619 8.68 13.19 9.271 13.619 9.046 12.924z"
            ></path>
            <path
              fill="#fff"
              d="M12.025 12.924L12.616 12.494 11.885 12.494 11.659 11.799 11.433 12.494 10.702 12.494 11.294 12.924 11.068 13.619 11.659 13.19 12.251 13.619 12.025 12.924z"
            ></path>
            <path
              fill="#fff"
              d="M13.539 7.463L14.13 7.033 13.399 7.033 13.173 6.338 12.947 7.033 12.216 7.033 12.808 7.463 12.582 8.158 13.173 7.729 13.765 8.158 13.539 7.463z"
            ></path>
            <path
              fill="#fff"
              d="M4.601 11.104L5.193 10.674 4.462 10.674 4.236 9.979 4.01 10.674 3.279 10.674 3.87 11.104 3.644 11.799 4.236 11.369 4.827 11.799 4.601 11.104z"
            ></path>
            <path
              fill="#fff"
              d="M7.58 11.104L8.172 10.674 7.441 10.674 7.215 9.979 6.989 10.674 6.258 10.674 6.849 11.104 6.623 11.799 7.215 11.369 7.806 11.799 7.58 11.104z"
            ></path>
            <path
              fill="#fff"
              d="M10.56 11.104L11.151 10.674 10.42 10.674 10.194 9.979 9.968 10.674 9.237 10.674 9.828 11.104 9.603 11.799 10.194 11.369 10.785 11.799 10.56 11.104z"
            ></path>
            <path
              fill="#fff"
              d="M13.539 11.104L14.13 10.674 13.399 10.674 13.173 9.979 12.947 10.674 12.216 10.674 12.808 11.104 12.582 11.799 13.173 11.369 13.765 11.799 13.539 11.104z"
            ></path>
            <path
              fill="#fff"
              d="M4.601 14.744L5.193 14.315 4.462 14.315 4.236 13.619 4.01 14.315 3.279 14.315 3.87 14.744 3.644 15.44 4.236 15.01 4.827 15.44 4.601 14.744z"
            ></path>
            <path
              fill="#fff"
              d="M7.58 14.744L8.172 14.315 7.441 14.315 7.215 13.619 6.989 14.315 6.258 14.315 6.849 14.744 6.623 15.44 7.215 15.01 7.806 15.44 7.58 14.744z"
            ></path>
            <path
              fill="#fff"
              d="M10.56 14.744L11.151 14.315 10.42 14.315 10.194 13.619 9.968 14.315 9.237 14.315 9.828 14.744 9.603 15.44 10.194 15.01 10.785 15.44 10.56 14.744z"
            ></path>
            <path
              fill="#fff"
              d="M13.539 14.744L14.13 14.315 13.399 14.315 13.173 13.619 12.947 14.315 12.216 14.315 12.808 14.744 12.582 15.44 13.173 15.01 13.765 15.44 13.539 14.744z"
            ></path>
          </svg>{" "}
        </span>
        USDA-Certified American Prime Beef. Free Shipping over $150.
      </div>
      <header className="relative bg-black z-10">
        <div className="pt-0 lg:pt-[14px] pb-0">
          <div className="max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
            <div className="grid grid-cols-12 gap-0 lg:gap-8 auto-cols-fr w-full items-center justify-center">
              <div className="col-span-9 lg:col-span-2 flex justify-start">
                <div className="flex flex-row lg:flex-col items-center gap-3 lg:gap-0 lg:items-start">
                  <div>
                    <Link
                      to="/"
                      reloadDocument
                      className="flex pt-3 pb-3 lg:pb-0 lg:pt-0"
                    >
                      <img
                        src={brand.data.logo}
                        alt="store-logo"
                        className="max-h-[50px]"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-span-8 hidden lg:flex justify-center">
                <div className="flex gap-2 items-center lg:px-0 w-full">
                  <div className="relative bg-[#333333] rounded-lg">
                    <Location />
                  </div>
                  <div className="relative bg-[#333333] rounded-lg flex-grow">
                    <SearchForm />
                  </div>
                </div>
              </div>

              <div className="col-span-3 lg:col-span-2 flex justify-end">
                <div className="flex gap-4 items-center">
                  <div className="text-white">
                    <Link to="/account">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="relative">
                    <Link
                      to="/cart"
                      prefetch="render"
                      className="text-white hover:text-gold focus:text-gold"
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 19 17"
                        width="30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L2.87153 1.62385C3.56699 1.85566 4.07989 2.44939 4.2082 3.17114L5.30668 9.3501C5.47638 10.3046 6.30628 11 7.2758 11H14.896C15.7868 11 16.5702 10.4109 16.8174 9.5551L18.2619 4.55508C18.6313 3.27618 17.6716 2 16.3404 2H7M8 14.5C8 15.3284 7.32843 16 6.5 16C5.67157 16 5 15.3284 5 14.5C5 13.6716 5.67157 13 6.5 13C7.32843 13 8 13.6716 8 14.5ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z"
                          style={{
                            strokeLinecap: "round",
                            strokeWidth: "1.25",
                            stroke: "currentcolor",
                          }}
                        ></path>
                      </svg>
                      {cartLoaded && !cartIsEmpty ? (
                        <span className="bg-white text-black w-[20px] h-[20px] text-xs absolute leading-[12px] -right-[7px] -top-[7px] rounded-[50%] flex items-center justify-center">
                          {productCount}
                        </span>
                      ) : null}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:hidden whitespace-nowrap mb-2">
                <div className="relative bg-[#333333] rounded-lg overflow-hidden">
                  <button
                    type="button"
                    onClick={() => {
                      setIsAddressesDialogOpen(true);
                    }}
                    className="flex absolute right-0 top-0 bottom-0"
                  >
                    <div
                      className="w-12 h-full -mr-[1px]"
                      style={{
                        background: "rgb(51,51,51)",
                        background:
                          "linear-gradient(90deg, rgba(51,51,51,0) 0%, rgba(51,51,51,1) 100%)",
                      }}
                    ></div>
                    <div className="w-5 h-full -mr-[1px] bg-[#333333]"></div>
                  </button>
                  <Location />
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
            <div className="border-top-0">
              <div className="grid grid-cols-12 gap-4 lg:gap-8 auto-cols-fr w-full">
                <div className="col-span-12 lg:col-span-12">
                  <div className="hidden lg:flex gap-1 w-full justify-between items-center">
                    {linklist &&
                      linklist.length > 0 &&
                      linklist.map((link, index) => {
                        const childlinks = link.sub_category;

                        return (
                          <React.Fragment key={link.id}>
                            <div className="group flex-grow cursor-pointer">
                              <Link
                                to={`/collections/${link.id}`}
                                className="flex justify-center items-center gap-1 text-xs xl:text-base px-3 py-3 rounded-lg text-white capitalize group-hover:text-primary hover:text-primary pl-0"
                              >
                                {link.title}
                                {childlinks.length > 1 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <polyline
                                      fill="none"
                                      stroke="currentcolor"
                                      strokeWidth="1.03"
                                      points="16 7 10 13 4 7"
                                    ></polyline>
                                  </svg>
                                ) : null}
                              </Link>
                              {childlinks.length > 1 ? (
                                <div
                                  className={`absolute left-0 right-0 bottom-0 translate-y-full hidden group-hover:block bg-black ${
                                    link.id !== "29760" ? "hidden" : null
                                  }`}
                                >
                                  <div className="relative max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
                                    <div className="pb-4">
                                      <div className="grid grid-cols-12 gap-2 auto-cols-fr w-full">
                                        {childlinks.map((childlink) => {
                                          return (
                                            <div
                                              key={childlink.id}
                                              className="col-span-3"
                                            >
                                              <Link
                                                to={`/collections/${link.id}/${childlink.id}`}
                                                className="px-3 py-2 flex truncate max-w-ful bg-white bg-opacity-10 text-white rounded-lg hover:bg-primary hover:text-black transition-all duration-300"
                                              >
                                                {childlink.title}
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <div className="flex lg:hidden gap-2 lg:px-0 w-full mb-2">
                    <div className="relative bg-[#333333] rounded-lg flex-grow">
                      <SearchForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {user.is_logged_in && user.refer_code ? (
        <div className="bg-blue-300 text-center min-h-12 px-2 py-2 flex justify-center items-center">
          <div>
            Invite your friends, and when they join, you both get to split $100.
            It's a win-win! 🎉 Your referral code is:{" "}
            <strong>{user.refer_code}</strong>
          </div>
        </div>
      ) : null}
    </>
  );
}
