import { useOutletContext } from "@remix-run/react";

export default function Banner({ banners }) {
  const { user } = useOutletContext();
  const { selectedAddress } = user;
  // Handle error state
  if (banners.error) {
    return <p>Error loading banners: {banners.message}</p>;
  }

  // Handle empty data state
  if (
    banners.loaded &&
    banners.success &&
    banners.data?.length === 0 &&
    banners.data.web_banners?.length === 0
  ) {
    return <p>No banners available.</p>;
  }

  return (
    <div>
      <div className="hidden lg:block">
        {banners.data.web_banners.map((banner) => {
          if (
            banner.title.includes("Meat") &&
            selectedAddress &&
            !selectedAddress.is_eligible_for_delivery
          ) {
            return (
              <div key={banner.id}>
                <a href="/collections/29839" className="flex justify-center">
                  <img
                    alt="store-banners"
                    className="object-cover"
                    src={banner.image}
                    width={2048}
                    height={500}
                  />
                </a>
              </div>
            );
          }
          if (
            !banner.title.includes("Meat") &&
            (selectedAddress?.is_eligible_for_delivery || !selectedAddress)
          ) {
            return (
              <div key={banner.id}>
                <a href="/collections/29839" className="flex justify-center">
                  <img
                    alt="store-banners"
                    className="object-cover"
                    src={banner.image}
                    width={2048}
                    height={500}
                  />
                </a>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="block lg:hidden">
        {banners.data.banners.map((banner) => {
          if (
            banner.title.includes("Meat") &&
            selectedAddress &&
            !selectedAddress.is_eligible_for_delivery
          ) {
            return (
              <div key={banner.id}>
                <a href="/collections/29839" className="flex justify-center">
                  <img
                    alt="store-banners"
                    className="object-cover"
                    src={banner.image}
                    width={2048}
                    height={500}
                  />
                </a>
              </div>
            );
          }
          if (
            !banner.title.includes("Meat") &&
            (selectedAddress?.is_eligible_for_delivery || !selectedAddress)
          ) {
            return (
              <div key={banner.id}>
                <a href="/collections/29839" className="flex justify-center">
                  <img
                    alt="store-banners"
                    className="object-cover"
                    src={banner.image}
                    width={2048}
                    height={500}
                  />
                </a>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
