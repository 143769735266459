import {
  Link,
  useNavigate,
  useNavigation,
  useOutletContext,
} from "@remix-run/react";
import ProductGrid from "../shared/ProductGrid";
import { useState } from "react";
import { LoadingIndicator } from "../shared/LoadingIndicator";

export default function Categories({ collections }) {
  const { user } = useOutletContext();
  const { selectedAddress } = user;
  const [isLoading, setIsLoading] = useState(null);
  const navigation = useNavigation();

  if (
    selectedAddress?.service_area?.categories &&
    selectedAddress?.service_area?.categories.length > 0 &&
    collections.length > 0
  ) {
    const categoryIds = selectedAddress.service_area.categories;

    // Filter collections to include only those with an ID in categoryIds
    collections = collections.filter((collection) =>
      categoryIds.includes(collection.id)
    );
  }

  if (!collections) return null;

  return (
    <>
      {user?.selectedAddress?.service_area?.title == "Nationwide" ? (
        <div className="text-xl lg:text-2xl px-6 pt-6 lg:pt-12 text-center">
          <div>
            Butchered Fresh to Order. <br className="block lg:hidden" />
            Shipped Frozen
          </div>
        </div>
      ) : null}
      <div className="max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
        <div className="relative hidden lg:flex py-6 lg:py-12 max-w-[1506px] w-full mx-auto px-[10px] lg:px-8 2xl:px-0 overflow-hidden">
          <div className="max-w-full w-full flex justify-center">
            {collections.map((collection) => {
              return (
                <Link
                  to={`/collections/${collection.id}`}
                  reloadDocument
                  key={collection.id}
                  style={{ width: "calc(100% / 9)" }}
                >
                  <span className="flex flex-col justify-center text-center">
                    <span className="relative block">
                      <img
                        src={collection.image}
                        alt=""
                        width="500"
                        height="500"
                      />
                      {isLoading === collection.id &&
                      navigation.state == "loading" ? (
                        <div className="absolute bg-white/80 left-0 top-0 right-0 bottom-0 flex justify-center items-center">
                          <LoadingIndicator />
                        </div>
                      ) : null}
                    </span>
                    <span className="capitalize">{collection.title}</span>
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="relative flex lg:hidden pb-8 pt-2 lg:py-12 max-w-[1506px] w-full mx-auto px-[10px] lg:px-8 2xl:px-0 overflow-hidden">
          <div className="grid grid-cols-12 gap-4 lg:gap-8 auto-cols-fr w-full items-center justify-center">
            {collections.map((collection) => {
              return (
                <div className="col-span-4" key={collection.id}>
                  <Link
                    to={`/collections/${collection.id}`}
                    reloadDocument
                    key={collection.id}
                    style={{ width: "calc(100% / 9)" }}
                    className="text-black"
                  >
                    <span className="flex flex-col justify-center text-center">
                      <span className="relative block">
                        <img
                          src={collection.image}
                          alt=""
                          width="500"
                          height="500"
                        />
                        {isLoading === collection.id &&
                        navigation.state == "loading" ? (
                          <div className="absolute bg-white/80 left-0 top-0 right-0 bottom-0 flex justify-center items-center">
                            <LoadingIndicator />
                          </div>
                        ) : null}
                      </span>
                      <span className="capitalize">{collection.title}</span>
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className={collections.length > 5 ? "hidden lg:block" : null}>
          {collections.map((collection, index) => {
            if (collection.products.length > 0) {
              return (
                <div
                  key={collection.id}
                  className={index === 0 ? null : "py-3 lg:py-12"}
                >
                  <div className="text-2xl lg:text-3xl flex justify-between border-b mb-3">
                    <div className="relative pb-3">
                      {collection.title}
                      <div className="bg-primary h-[2px] absolute bottom-0 left-0 right-0"></div>
                    </div>
                    <div>
                      <Link
                        to={`/collections/${collection.id}`}
                        reloadDocument
                        className="text-base underline cursor-pointer opacity-75 hover:opacity-100"
                      >
                        Show More
                      </Link>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-[10px] lg:gap-[10px] auto-cols-fr w-full">
                    <ProductGrid products={collection.products} />
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
}
