export default function AddressItem({
  selectedAddress,
  address,
  handleSelectedAddress,
  isFirst,
  isLast,
}) {
  return (
    <div className="flex-grow w-full relative mb-1">
      <label
        htmlFor={`checkout-address-${address.id}`}
        className={`flex justify-between items-center gap-4 cursor-pointer`}
      >
        <div className="relative flex items-center gap-3 w-full flex-grow">
          {handleSelectedAddress ? (
            <div>
              <input
                type="radio"
                name="shipping_methods"
                id={`checkout-address-${address.id}`}
                checked={selectedAddress?.id === address.id}
                onChange={() => handleSelectedAddress(address)}
                className={
                  selectedAddress?.id === address.id
                    ? "w-[18px] h-[18px] bg-white rounded-full border-[6px] border-primary appearance-none"
                    : "w-[18px] h-[18px] bg-white rounded-full border border-[#ddd] appearance-none"
                }
              />
            </div>
          ) : null}
          <div
            className={`min-w-0 w-full rounded-xl py-[5px] px-[10px] border ${
              selectedAddress?.id === address.id
                ? "border-primary"
                : "border-[#f0f0f0]"
            }`}
          >
            <div className="flex flex-col items-start">
              <div className="max-w-[80%] w-full font-bold text-base text-ellipsis whitespace-nowrap overflow-hidden flex gap-2">
                <span>
                  {address.first_name} {address.last_name}{" "}
                </span>
              </div>
              <div className="max-w-[80%] w-full text-sm text-ellipsis whitespace-nowrap overflow-hidden">
                {address.address}
              </div>
              <div className="text-sm max-w-[80%] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                {address.address2}
              </div>
              <div className="text-sm max-w-[80%] w-full text-ellipsis whitespace-nowrap overflow-hidden">
                {address.mobile}
              </div>
              {address.is_eligible_for_delivery == false ? (
                <div className="text-sm max-w-[80%] text-green font-bold mt-1 w-full">
                  This address is eligible for nationwide shipping of meats,
                  fish, and cheese.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </label>
      {address.set_default_address === "1" ? (
        <div className="italic text-black/50 text-sm absolute right-[10px] bottom-[10px]">
          Default
        </div>
      ) : null}
    </div>
  );
}
