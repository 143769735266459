import { createContext, useState, useEffect, useContext } from "react";

// Create a CartContext
const CartContext = createContext();

// Create a provider component
export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [cartLoaded, setCartLoaded] = useState(false); // Track if the cart is loaded
  const [addressToBeEdited, setAddressToBeEdited] = useState(null);
  const [cartLocked, setCartLocked] = useState(false); // Track if the cart is locked
  const [isAddressesDialogOpen, setIsAddressesDialogOpen] = useState(false);
  const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = useState(false);

  // Load cart from localStorage only on the client
  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        setCart(JSON.parse(storedCart));
      }
      setCartLoaded(true); // Set cartLoaded to true once cart is loaded
    }
  }, []); // Run once on component mount

  // Save cart to localStorage whenever the cart state changes
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (cart.length > 0) {
        // Save the cart to localStorage when there are items in the cart
        localStorage.setItem("cart", JSON.stringify(cart));
      } else {
        // Remove the cart from localStorage if the cart is empty
        localStorage.removeItem("cart");
      }
    }
  }, [cart]);

  // Helper function to check if cart is locked
  const ensureCartUnlocked = () => {
    if (cartLocked) {
      alert(
        "Your cart is currently locked while we update your store selection. Please wait until the process is complete."
      );
      return false;
    }
    return true;
  };

  const addToCart = (productData, variant, quantity = 1) => {
    if (!ensureCartUnlocked()) return; // Prevent adding to the cart if it's locked

    const price = parseFloat(variant.price);
    const weight = parseFloat(
      variant.weight.replace(` ${variant.unit_type}`, "")
    );
    const price_per_unit = (price / weight).toFixed(2);
    const product = {
      id: productData.id,
      title: productData.title,
      price: variant.price,
      price_per_unit,
      weight_without_unit: weight.toFixed(2),
      mrp_price: variant.mrp_price,
      discount: variant.discount,
      image: productData.image_url,
      thumbnail: productData.image_300_200,
      isTaxEnable: productData.isTaxEnable,
      description: productData.description,
      subcategory: productData.parent_category,
      weight: variant.weight,
      variant_id: variant.id, // Use the passed variant's id
      unit_type: variant.unit_type,
      stock: variant.stock,
      stock_type: variant.stock_type,
      min_stock_alert: variant.min_stock_alert,
      nutrient: productData.nutrient,
      is_weight_required: productData.is_weight_required,
      collection_id: productData.category_ids,
    };

    // Transform the product data to match the desired structure
    const transformedProduct = {
      product,
      quantity: quantity,
    };

    const orderItem = _createOrderItems(transformedProduct);

    transformedProduct.orderItem = { ...orderItem };

    // Check if the item with the same product and variant is already in the cart
    const existingItem = cart.find(
      (item) =>
        item.product.id === transformedProduct.product.id &&
        item.product.variant_id === transformedProduct.product.variant_id
    );

    let updatedCart;
    if (existingItem) {
      // Update quantity if the product and variant already exist
      updatedCart = cart.map((item) =>
        item.product.id === transformedProduct.product.id &&
        item.product.variant_id === transformedProduct.product.variant_id
          ? { ...item, quantity: item.quantity + quantity }
          : item
      );
    } else {
      // Add new item to cart
      updatedCart = [...cart, transformedProduct];
    }

    setCart(updatedCart);
  };

  const addCartItem = (cartItem) => {
    if (!ensureCartUnlocked()) return; // Prevent adding to the cart if it's locked

    const existingItem = cart.find(
      (item) => item.product.id === cartItem.product.id
    );

    let updatedCart;
    if (existingItem) {
      // If the product already exists, update the quantity
      updatedCart = cart.map((item) =>
        item.product.id === cartItem.product.id
          ? {
              ...item,
              orderItem: {
                ...item.orderItem,
                quantity: item.quantity + cartItem.quantity,
              },
              quantity: item.quantity + cartItem.quantity,
            }
          : item
      );
    } else {
      // If the product doesn't exist, add it to the cart
      updatedCart = [...cart, cartItem];
    }

    setCart(updatedCart);
  };

  // Remove item from cart
  const removeFromCart = (productId, variantId) => {
    if (!ensureCartUnlocked()) return; // Prevent removing from the cart if it's locked

    const updatedCart = cart.filter(
      (item) =>
        item.product.id !== productId || item.product.variant_id !== variantId
    );
    setCart(updatedCart);
  };

  // Update item quantity
  const updateQuantity = (productId, variantId, quantity) => {
    if (!ensureCartUnlocked()) return; // Prevent updating the quantity if the cart is locked

    const updatedCart = cart.map((item) =>
      item.product.id === productId && item.product.variant_id === variantId
        ? { ...item, orderItem: { ...item.orderItem, quantity }, quantity }
        : item
    );
    setCart(updatedCart);
  };

  // Check if item is in cart
  const isInCart = (productId, variantId) => {
    return cart.some(
      (item) =>
        item.product.id === productId && item.product.variant_id === variantId
    );
  };

  // Get the quantity of a specific item in the cart
  const getQuantity = (variantId) => {
    const item = cart.find((item) => item.product.variant_id === variantId);
    return item ? item.quantity : 0;
  };

  // Retrieve a list of items from a specific collection in the cart
  const getItemsByCollection = (collectionId) => {
    return cart.filter((item) => item.product.collection_id === collectionId);
  };

  // Filter items by a specific collection when removing items
  const removeItemsByCollection = (collectionId) => {
    const updatedCart = cart.filter(
      (item) => item.product.collection_id !== collectionId
    );
    setCart(updatedCart);
  };

  // Calculate the total number of items in the cart
  const cartSize = cart.reduce((total, item) => total + item.quantity, 0);

  // Get the number of unique products in the cart
  const productCount = cart.length; // Number of distinct products

  // Check if the cart is empty
  const cartIsEmpty = cart.length === 0;

  // Calculate the total price of all items in the cart
  const cartTotalPrice = cart
    .reduce(
      (total, item) => total + parseFloat(item.product.price) * item.quantity,
      0
    )
    .toFixed(2);

  // Clear all items from the cart
  const clearCart = () => {
    if (!ensureCartUnlocked()) return; // Prevent clearing the cart if it's locked
    setCart([]);
  };

  // Get a product from the cart by ID
  const getProduct = (productId) => {
    return cart.find((item) => item.product.id === productId) || null;
  };

  const _createOrderItems = (item) => {
    const cartItem = {
      cart_index_id: _randomCartIndexId(),
      product_id: item.product.id,
      product_name: item.product.title,
      isTaxEnable: item.product.isTaxEnable,
      variant_id: item.product.variant_id,
      weight: item.product.weight,
      mrp_price: item.product.mrp_price,
      price: item.product.price,
      discount: item.product.discount,
      unit_type: "",
      quantity: item.quantity,
      product_type: 1,
      nutrient: item.product.nutrient,
      primary_product: 1,
      repeat_list: item.product.repeat_list ? item.product.repeat_list : "",
      order_list: item.product.order_details_list
        ? item.product.order_details_list
        : [],
      add_on_list: item.product.add_on_list ? item.product.add_on_list : "",
      collection_id: item.product.collection_id,
    };
    if (item.product.variants_list) {
      for (let i = 0; i < item.product.variants_list.length; i++) {
        item.product.variants_list[i]["quantity"] = item.quantity;
      }
      return { cartItem, ...item.product.variants_list };
    } else {
      return cartItem;
    }
  };

  const _randomCartIndexId = () => {
    const randomNumber = Math.floor(Math.random() * 10000) + 1;
    const formattedString = `Cart${randomNumber}`;

    return formattedString;
  };

  // Context value to be shared
  const value = {
    cart,
    cartLoaded, // Expose cartLoaded to know if cart is fully loaded
    cartSize,
    productCount,
    cartIsEmpty,
    cartTotalPrice,
    isAddressesDialogOpen,
    setIsAddressesDialogOpen,
    isAddAddressDialogOpen,
    setIsAddAddressDialogOpen,
    addressToBeEdited,
    setAddressToBeEdited,
    addCartItem,
    getProduct,
    clearCart,
    addToCart,
    removeFromCart,
    updateQuantity,
    isInCart,
    getQuantity, // Expose the getQuantity function
    cartLocked, // Expose cartLocked
    setCartLocked, // Expose setCartLocked to allow locking/unlocking cart
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

// Custom hook to use the Cart context
export function useCart() {
  return useContext(CartContext);
}
