import { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useNavigation,
  useOutletContext,
} from "@remix-run/react";
import MapCompenent from "./MapCompenent";
import { formatAddress } from "../../utils/helper";
import { FORMAT_PHONE_NUMBER } from "../../config/constants";
import { LoadingIndicator } from "./LoadingIndicator";

export default function AddressForm({
  onCancel,
  googleMapsAPIKEY,
  actionData,
  addresses,
  initialValues = {}, // Initial values for editing
  isEdit = false, // Flag to indicate if this is for editing
}) {
  //const { user } = useOutletContext();
  const [autoCompletedAddress, setAutoCompletedAddress] = useState(null);
  const [phone, setPhone] = useState(initialValues?.phone || "");
  const [isDefaultAddress, setIsDefaultAddress] = useState(
    initialValues?.defaultAddress || false
  );
  const [lat, setLat] = useState(initialValues?.lat || ""); // Lat state
  const [lng, setLng] = useState(initialValues?.lng || ""); // Lng state
  const [address, setAddress] = useState(initialValues?.address || ""); // Address state
  const navigation = useNavigation();

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = FORMAT_PHONE_NUMBER(e.target.value);
    setPhone(formattedPhoneNumber);

    if (formattedPhoneNumber.replace(/[^\d]/g, "").length === 10) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const handleDefaultAddressChange = (e) => {
    if (addresses && addresses.length > 0) {
      setIsDefaultAddress(e.target.checked);
    }
  };

  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {
        types: ["address"],
        componentRestrictions: { country: "us" },
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      // Ensure address_components exists before accessing it
      const addressComponents = place.address_components || [];

      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const zipCode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.long_name;

      // Extract formatted address and coordinates if they exist
      const formattedAddress = place.formatted_address || "";
      const newLat = place.geometry?.location?.lat() || 0;
      const newLng = place.geometry?.location?.lng() || 0;

      // Update address details in state
      setAutoCompletedAddress({
        address: formattedAddress,
        lat: newLat,
        lng: newLng,
      });
      setAddress(formattedAddress);
      setLat(newLat);
      setLng(newLng);

      // Update city and zip code fields if available
      if (city) document.getElementById("city").value = city;
      if (zipCode) document.getElementById("zipCode").value = zipCode;
    });

    if (!addresses || addresses.length === 0) {
      setIsDefaultAddress(true);
    }
  }, []);

  const isLoading = navigation.state === "submitting";

  const getError = (fieldName) => {
    return actionData?.validationErrors?.find(
      (error) => error.field === fieldName
    );
  };

  //console.log("Initial Values", initialValues);

  return (
    <div className="max-w-lg mx-auto relative">
      <Form method="post" className="space-y-3">
        <div className="grid grid-cols-12 gap-[10px] lg:gap-[40px] auto-cols-fr w-full">
          <div className="col-span-6">
            <label className="block text-sm font-medium text-gray-700">
              <span>
                First Name <span className="text-red-500">*</span>
              </span>
              {getError("firstName") && (
                <div className="text-red-500 text-sm">
                  {getError("firstName").message}
                </div>
              )}
              <input
                type="text"
                name="firstName"
                defaultValue={initialValues?.firstName || ""} // Set initial value for editing
                className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </label>
          </div>
          <div className="col-span-6">
            <label className="block text-sm font-medium text-gray-700">
              <span>
                Last Name <span className="text-red-500">*</span>
              </span>
              {getError("lastName") && (
                <div className="text-red-500 text-sm">
                  {getError("lastName").message}
                </div>
              )}
              <input
                type="text"
                name="lastName"
                defaultValue={initialValues?.lastName || ""} // Set initial value for editing
                className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </label>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            <span>
              Phone <span className="text-red-500">*</span>
            </span>
            {getError("phone") && (
              <div className="text-red-500 text-sm">
                {getError("phone").message}
              </div>
            )}
            <input
              type="text"
              name="phone"
              onChange={handlePhoneNumberChange}
              value={phone} // Controlled component for phone
              className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            <span>
              Address <span className="text-red-500">*</span>
            </span>
            {getError("address") && (
              <div className="text-red-500 text-sm">
                {getError("address").message}
              </div>
            )}
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Start typing your address..."
              value={address} // Controlled component for address
              onChange={(e) => setAddress(e.target.value)} // Update address state
              className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>

        {autoCompletedAddress ? (
          <div>
            <MapCompenent
              googleMapsAPIKEY={googleMapsAPIKEY}
              address={autoCompletedAddress}
              classes="h-[150px] lg:h-[200px]"
            />
          </div>
        ) : null}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            <span>
              City <span className="text-red-500">*</span>
            </span>
            {getError("city") && (
              <div className="text-red-500 text-sm">
                {getError("city").message}
              </div>
            )}
            <input
              type="text"
              id="city"
              name="city"
              defaultValue={initialValues?.city || ""} // Set initial value for editing
              className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            <span>
              Zip Code <span className="text-red-500">*</span>
            </span>
            {getError("zipCode") && (
              <div className="text-red-500 text-sm">
                {getError("zipCode").message}
              </div>
            )}
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              defaultValue={initialValues?.zipCode || ""} // Set initial value for editing
              className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            <span>Apartment, suite, home, office.</span>
            {getError("apartment") && (
              <div className="text-red-500 text-sm">
                {getError("apartment").message}
              </div>
            )}
            <input
              type="text"
              name="apartment"
              defaultValue={initialValues?.apartment || ""} // Set initial value for editing
              className="mt-1 block bg-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>

        {/* Checkbox for default address */}
        <div className="relative flex items-center">
          <input
            type="checkbox"
            id="defaultAddress"
            name="defaultAddress"
            checked={isDefaultAddress} // Controlled component for checkbox
            readOnly={!addresses || addresses.length === 0 || isDefaultAddress}
            onChange={
              isEdit && isDefaultAddress ? () => {} : handleDefaultAddressChange
            }
            className={
              isDefaultAddress
                ? isEdit
                  ? "w-[18px] opacity-70 h-[18px] rounded-sm border border-primary bg-primary  appearance-none"
                  : "w-[18px] h-[18px] rounded-sm border border-primary bg-primary  appearance-none"
                : "w-[18px] h-[18px] bg-white rounded-sm border border-[#ddd] appearance-none"
            }
          />
          <label
            htmlFor="defaultAddress"
            className="ml-2 block text-sm text-gray-900"
          >
            {isDefaultAddress ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 14"
                focusable="false"
                aria-hidden="true"
                className="fill-none w-[10px] h-[10px] stroke-white absolute top-1 left-1"
              >
                <path
                  style={{
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    stroke: "white",
                  }}
                  d="m12.1 2.8-5.877 8.843a.35.35 0 0 1-.54.054L1.4 7.4"
                ></path>
              </svg>
            ) : null}
            Set as default address
          </label>
        </div>

        {/* Hidden email field */}
        <input type="hidden" name="action" value="addEditAddress" />
        <input
          type="hidden"
          name="addressId"
          value={initialValues?.addressId || ""}
        />
        <input
          id="lat"
          type="hidden"
          name="lat"
          value={lat} // Use the lat state value
        />
        <input
          id="lng"
          type="hidden"
          name="lng"
          value={lng} // Use the lng state value
        />

        <div className="flex justify-end">
          <div className="flex items-center gap-4 max-w-[60%] w-full">
            <button
              type="button"
              onClick={onCancel}
              className="flex justify-center w-full"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={navigation.state === "submitting"}
              className="w-full h-12 flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:bg-gray-200 disabled:text-black"
            >
              {!isLoading ? isEdit ? "Update" : "Save" : <LoadingIndicator />}
            </button>
          </div>
        </div>
      </Form>

      {isEdit && !isDefaultAddress ? (
        <Form method="post" className="text-red-500 absolute left-0 bottom-0">
          <input
            type="hidden"
            name="address_id"
            value={initialValues.addressId}
          />
          <input type="hidden" name="action" value="deleteAddress" />
          <button type="submit" className="h-[48px] flex items-center">
            {!isLoading ? "Delete" : <LoadingIndicator />}
          </button>
        </Form>
      ) : null}
    </div>
  );
}
