import { useEffect, useState } from "react";

export default function MapCompenent({ address, googleMapsAPIKEY, classes }) {
  if (!googleMapsAPIKEY || !address || !address.lat || !address.lng) {
    return null;
  }

  return (
    <div className="w-full">
      <iframe
        className={`w-full ${classes}`}
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsAPIKEY}&q=${address.address}&center=${address.lat},${address.lng}&zoom=16&maptype=roadmap`}
      ></iframe>
    </div>
  );
}
