import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import {
  LANGUAGE,
  STORE_ID,
  BRAND_ID,
  API_VERSION,
  BASE_URL,
  FETCH_DATA,
} from "../config/constants";
import Categories from "../components/home/Categories";
import Banners from "../components/home/Banners";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import { formatProductVariants } from "../utils/helper";

export const meta = () => {
  const canonicalUrl = `https://ourismarket.com`;
  return [
    { title: "Ouri's Market | Premium Kosher Foods Since 1976" },
    {
      name: "description",
      content:
        "Fresh since 1976! Discover Ouri's Market's premium kosher products. From produce and specialty kosher dairy to bakery, butcher, fish counter, frozen foods, and beverages, we bring top-quality offerings to the kosher market, promoting a delicious and wholesome lifestyle.",
    },
    {
      name: "keywords",
      content:
        "kosher, kosher foods, premium kosher products, healthy lifestyle, fresh ingredients, Ouri's Market",
    },
    { rel: "canonical", href: canonicalUrl },
  ];
};

export const loader = async ({ request }) => {
  const page = 1;
  const limit = 12;
  // Start async operations in parallel to avoid waiting for each one sequentially
  const [banners, collectionsResponse] = await Promise.all([
    FETCH_DATA(
      `${BASE_URL}/${BRAND_ID}/${API_VERSION}/marketplace/homescreen/banners`,
      "data",
      "POST",
      { city: "New York" }
    ),
    FETCH_DATA(
      `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/inventory/getCategories?lang=${LANGUAGE}`,
      "data"
    ),
  ]);

  // Set default values for collections
  const collections =
    collectionsResponse?.success && collectionsResponse?.data?.length
      ? collectionsResponse.data
      : [];

  // Common part of the URL for fetching collection data
  const collectionDataUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/${STORE_ID}/inventory/getCollectionData`;

  // Fetch collection data in parallel
  const promises = collections.map((collection) =>
    FETCH_DATA(
      `${collectionDataUrl}/${collection.id}/${page}/${limit}`,
      ["products", "size", "data"],
      "POST",
      {},
      "application/x-www-form-urlencoded"
    )
  );

  // Create cleaned collections map
  const cleanedCollections = collections.reduce((acc, { id, title, image }) => {
    acc[id] = { id, title, image };
    return acc;
  }, {});

  // Await and process results of all promises
  const results = await Promise.all(promises);

  const productLists = results.reduce((acc, response) => {
    const parentId = response.data?.data?.[0]?.parent_id ?? null;
    if (!parentId) return acc;

    const products = formatProductVariants(response.data?.products);

    const collectionData = cleanedCollections[parentId];
    if (collectionData) {
      acc.push({
        ...collectionData,
        products: products ?? [],
      });
    }
    return acc;
  }, []);

  return json({
    banners,
    collections: [...productLists],
  });
};

export default function Index() {
  const { banners, collections } = useLoaderData();

  return (
    <div>
      <Header />
      <div className="flex flex-col">
        <div className="flex flex-col items-center">
          <Banners banners={banners} />

          <Categories collections={collections} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
